import './App.css';
import algosdk from "algosdk";
import { DeflyWalletConnect } from '@blockshake/defly-connect';
import { useEffect } from 'react';



const deflywallet = new DeflyWalletConnect()
const algod_address = "https://mainnet-api.algonode.cloud"
const algod_token = ""
const headers = {"X-Algo-API-Token": algod_token }
const algodClient = new algosdk.Algodv2(algod_token, algod_address, headers);
const address = localStorage.getItem('address');


//MaintNet
const voteAddress1 = '54ZNDTZQIX5N5B6NDS62IOXX4AKLYY3V44EIXW3EFYCYBUS7H3PRAHK2EU'
const voteAddress2 = 'ANYU5ROV2WW5O23UGAQQIKL4AFZIF34BO5V6IIBPSLQ2WU5WLAW7U4MARQ'
const voteAddress3 = 'SEHNTAQLB5Q524Q6DCB5DDB3ETWALG3HMI3LHVGQ4PVTNI2X5JO6ZKK2FU'
const voteAddress4 = '2XWKKD573A26HJD7ZTXWTLNOWIBE33FWLQXU63KH2XVN3TXUO35YVKXDZA'
const voteAddress5 = 'I7262NKWAYGLTUFPYHQEVYOMTUOO22DXCT2JXTIAK2TXKAPSWF4BQBDBFU'
const voteAddress6 = 'TDSPWDWLZFQUFH2ZPO3D47SDT5HAXV24YNGH5TXHWISSFFWIK3E62PDF6M'
const voteAddress7 = 'MFDNEMX3YYZE43GHNYFCTPAKXFJ4P7X7M72STVEYIUN37RKCJC2WIVXW7U'
const voteAddress8 = 'K6D2UX3JUXH5P5BPQNH5DH3UJEXFSUMFX4EJJX23XI3AFGMAWD7JKMLSEE'
const voteAddress9 = 'PMYUXEDSBHVCDVOV43OPHNXDYQIUGF4SE5F3F4LHI4UYAWFH5JM5XXJSBQ'
const voteAddress10 = 'DAF7NMKZIJA662V62H6BXKA2DN2PSY6WZYPTIA7FK6FF6JI3ICDEXHXLKQ'
const voteAddress11 = 'YSDMPFVXWOAU4GSHV2RGOD3IMJMUJUMA4C7UMJA2MIVFQYEC23GCZNF3AY'
const voteAddress12 = '47E66PFKIB6JL56JGJI3WFVAQELRFDTU72RR2FUQDPZ5HZHF542CC3FLDA'
const voteAddress13 = 'E4SLKAGG7JKU2IYAH3XFD4M3A46LEAPDBZMUBFTJCWOGUNMEZOSBDASDTA'
const voteAddress14 = 'NZLWIHYIFFDWIQHV57UWELV4IDVG2HMYTAO4MIBWVKARCSVB5DDAU3AJCU'
const voteAddress15 = '2BMK4QKN4SAG6AGYVNCJYYW6KU54DQCIMAMBEXQ34ECMPJYGWNOMNIFEHE'
const voteAddress16 = 'KPNOTCVCARH7NBEHUH5WISYQM6E4DUATF6KIFIXWI6ROKERN56LQZBA4BM'
const voteAddress17 = 'M6DECPH43CQAZDL3AADT55MQHP3IMONYDZ7HEWZP4NBSODRD52Z5JZYERE'
const voteAddress18 = 'SK5KAWQWWGGETPQ5C2MUULEM26JDTKHTO2UC53UNUTQXFRAGTPYGE4FZPQ'
const voteAddress19 = 'OR5CZ4ZSGDP5AHG2KESU5DKKKBLB7JSMICIZ57R25KQIBDJZT7FO367U24'
const voteAddress20 = 'RQN62RK7DHROCD2S3CFI5VDFYZZRUIXIVBSCQUCJB5PNDPFNLNMUSAGCVA'
const voteAddress21 = '65IND3BZ5FSSHPQDYEAR7UXS7RNCFI3ZXOAQAECLXS45KDCYVHTWJASOJU'
const voteAddress22 = 'IQ2ZAIZ5ICD6RTIBWCEH2ZLHUPMXI6TU3XJWC2MRX2Q7HQFGLVEBXUPZU4'
const voteAddress23 = '4HNGLG3OZZAJULILL2IDXDLKJYSJDDDKQ2BWXQRCJGJ4H2NYU4LSWTDPEM'
const voteAddress24 = 'HYJ4ITHVZMCFZRA53Y267Q2TWI5S3PIEGKFHONH3ECIIK6CV2V3ZRY2X5A'
const voteAddress25 = 'DE5PY5PSCFZALDVBQ34EBRIFMUTIHX4JAK5SSDZRTSFEABU656BYK633SE'
const voteAddress26 = 'SYFT3RGBB6EQNJOOPDN5E3REXZGXFHMQHQ5GSALXGITXRBHK5ZIBOYWE6A'
const voteAddress27 = 'RRRENMKQXY47SILDICCEJRSVAWWQXGRUKYK6VDDAG557Z7RNMMEZTDOCVQ'
const voteAddress28 = 'IDCYBZ7KUBDQPSKYCACZB2T5VJJ3KOLVXOO7L3JFJRQQ4BWI5YXDDJ7GNI'

//Asset ID
//MainNet
const ASSET_ID = 297995609;

// DeFly Connect
async function walletConnect() {
  const newAccounts= await deflywallet.connect()
  localStorage.setItem("address", newAccounts[0]);
  window.location.reload()
  }
const disconnect = () => {
  deflywallet.disconnect()
  localStorage.removeItem("address");
  window.location.reload()
  }

//////////////////////////////////

/////////////////////////
const vote_transaction1 = async () => {
  try{
    const suggestedParams = await algodClient.getTransactionParams().do();
    const txn = algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
      from: address,
      to: voteAddress1,
      amount: 1000000,
      assetIndex: ASSET_ID,
      suggestedParams,
    });
    const optInTxn = [{txn : txn, signers: [address]}]
    const signedTxn = await deflywallet.signTransaction([optInTxn])
    const success = await algodClient.sendRawTransaction(signedTxn).do();
    return success
  }
  catch(err){
    console.log(err)
    return false
  }
  }
/////////////
const vote_transaction2 = async () => {
  try{
    const suggestedParams = await algodClient.getTransactionParams().do();
    const txn = algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
      from: address,
      to: voteAddress2,
      amount: 1000000,
      assetIndex: ASSET_ID,
      suggestedParams,
    });
    const optInTxn = [{txn : txn, signers: [address]}]
    const signedTxn = await deflywallet.signTransaction([optInTxn])
    const success = await algodClient.sendRawTransaction(signedTxn).do();
    return success
  }
  catch(err){
    console.log(err)
    return false
  }
  }

/////////////
const vote_transaction3 = async () => {
  try{
    const suggestedParams = await algodClient.getTransactionParams().do();
    const txn = algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
      from: address,
      to: voteAddress3,
      amount: 1000000,
      assetIndex: ASSET_ID,
      suggestedParams,
    });
    const optInTxn = [{txn : txn, signers: [address]}]
    const signedTxn = await deflywallet.signTransaction([optInTxn])
    const success = await algodClient.sendRawTransaction(signedTxn).do();
    return success
  }
  catch(err){
    console.log(err)
    return false
  }
  }

/////////////
const vote_transaction4 = async () => {
  try{
    const suggestedParams = await algodClient.getTransactionParams().do();
    const txn = algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
      from: address,
      to: voteAddress4,
      amount: 1000000,
      assetIndex: ASSET_ID,
      suggestedParams,
    });
    const optInTxn = [{txn : txn, signers: [address]}]
    const signedTxn = await deflywallet.signTransaction([optInTxn])
    const success = await algodClient.sendRawTransaction(signedTxn).do();
    return success
  }
  catch(err){
    console.log(err)
    return false
  }
  }

/////////////
const vote_transaction5 = async () => {
  try{
    const suggestedParams = await algodClient.getTransactionParams().do();
    const txn = algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
      from: address,
      to: voteAddress5,
      amount: 1000000,
      assetIndex: ASSET_ID,
      suggestedParams,
    });
    const optInTxn = [{txn : txn, signers: [address]}]
    const signedTxn = await deflywallet.signTransaction([optInTxn])
    const success = await algodClient.sendRawTransaction(signedTxn).do();
    return success
  }
  catch(err){
    console.log(err)
    return false
  }
  }

/////////////
const vote_transaction6 = async () => {
  try{
    const suggestedParams = await algodClient.getTransactionParams().do();
    const txn = algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
      from: address,
      to: voteAddress6,
      amount: 1000000,
      assetIndex: ASSET_ID,
      suggestedParams,
    });
    const optInTxn = [{txn : txn, signers: [address]}]
    const signedTxn = await deflywallet.signTransaction([optInTxn])
    const success = await algodClient.sendRawTransaction(signedTxn).do();
    return success
  }
  catch(err){
    console.log(err)
    return false
  }
  }

/////////////
const vote_transaction7 = async () => {
  try{
    const suggestedParams = await algodClient.getTransactionParams().do();
    const txn = algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
      from: address,
      to: voteAddress7,
      amount: 1000000,
      assetIndex: ASSET_ID,
      suggestedParams,
    });
    const optInTxn = [{txn : txn, signers: [address]}]
    const signedTxn = await deflywallet.signTransaction([optInTxn])
    const success = await algodClient.sendRawTransaction(signedTxn).do();
    return success
  }
  catch(err){
    console.log(err)
    return false
  }
  }

/////////////
const vote_transaction8 = async () => {
  try{
    const suggestedParams = await algodClient.getTransactionParams().do();
    const txn = algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
      from: address,
      to: voteAddress8,
      amount: 1000000,
      assetIndex: ASSET_ID,
      suggestedParams,
    });
    const optInTxn = [{txn : txn, signers: [address]}]
    const signedTxn = await deflywallet.signTransaction([optInTxn])
    const success = await algodClient.sendRawTransaction(signedTxn).do();
    return success
  }
  catch(err){
    console.log(err)
    return false
  }
  }

/////////////
const vote_transaction9 = async () => {
  try{
    const suggestedParams = await algodClient.getTransactionParams().do();
    const txn = algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
      from: address,
      to: voteAddress9,
      amount: 1000000,
      assetIndex: ASSET_ID,
      suggestedParams,
    });
    const optInTxn = [{txn : txn, signers: [address]}]
    const signedTxn = await deflywallet.signTransaction([optInTxn])
    const success = await algodClient.sendRawTransaction(signedTxn).do();
    return success
  }
  catch(err){
    console.log(err)
    return false
  }
  }

/////////////
const vote_transaction10 = async () => {
  try{
    const suggestedParams = await algodClient.getTransactionParams().do();
    const txn = algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
      from: address,
      to: voteAddress10,
      amount: 1000000,
      assetIndex: ASSET_ID,
      suggestedParams,
    });
    const optInTxn = [{txn : txn, signers: [address]}]
    const signedTxn = await deflywallet.signTransaction([optInTxn])
    const success = await algodClient.sendRawTransaction(signedTxn).do();
    return success
  }
  catch(err){
    console.log(err)
    return false
  }
  }

/////////////
const vote_transaction11 = async () => {
  try{
    const suggestedParams = await algodClient.getTransactionParams().do();
    const txn = algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
      from: address,
      to: voteAddress11,
      amount: 1000000,
      assetIndex: ASSET_ID,
      suggestedParams,
    });
    const optInTxn = [{txn : txn, signers: [address]}]
    const signedTxn = await deflywallet.signTransaction([optInTxn])
    const success = await algodClient.sendRawTransaction(signedTxn).do();
    return success
  }
  catch(err){
    console.log(err)
    return false
  }
  }

/////////////
const vote_transaction12 = async () => {
  try{
    const suggestedParams = await algodClient.getTransactionParams().do();
    const txn = algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
      from: address,
      to: voteAddress12,
      amount: 1000000,
      assetIndex: ASSET_ID,
      suggestedParams,
    });
    const optInTxn = [{txn : txn, signers: [address]}]
    const signedTxn = await deflywallet.signTransaction([optInTxn])
    const success = await algodClient.sendRawTransaction(signedTxn).do();
    return success
  }
  catch(err){
    console.log(err)
    return false
  }
  }

/////////////
const vote_transaction13 = async () => {
  try{
    const suggestedParams = await algodClient.getTransactionParams().do();
    const txn = algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
      from: address,
      to: voteAddress13,
      amount: 1000000,
      assetIndex: ASSET_ID,
      suggestedParams,
    });
    const optInTxn = [{txn : txn, signers: [address]}]
    const signedTxn = await deflywallet.signTransaction([optInTxn])
    const success = await algodClient.sendRawTransaction(signedTxn).do();
    return success
  }
  catch(err){
    console.log(err)
    return false
  }
  }

/////////////
const vote_transaction14 = async () => {
  try{
    const suggestedParams = await algodClient.getTransactionParams().do();
    const txn = algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
      from: address,
      to: voteAddress14,
      amount: 1000000,
      assetIndex: ASSET_ID,
      suggestedParams,
    });
    const optInTxn = [{txn : txn, signers: [address]}]
    const signedTxn = await deflywallet.signTransaction([optInTxn])
    const success = await algodClient.sendRawTransaction(signedTxn).do();
    return success
  }
  catch(err){
    console.log(err)
    return false
  }
  }

/////////////
const vote_transaction15 = async () => {
  try{
    const suggestedParams = await algodClient.getTransactionParams().do();
    const txn = algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
      from: address,
      to: voteAddress15,
      amount: 1000000,
      assetIndex: ASSET_ID,
      suggestedParams,
    });
    const optInTxn = [{txn : txn, signers: [address]}]
    const signedTxn = await deflywallet.signTransaction([optInTxn])
    const success = await algodClient.sendRawTransaction(signedTxn).do();
    return success
  }
  catch(err){
    console.log(err)
    return false
  }
  }

/////////////
const vote_transaction16 = async () => {
  try{
    const suggestedParams = await algodClient.getTransactionParams().do();
    const txn = algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
      from: address,
      to: voteAddress16,
      amount: 1000000,
      assetIndex: ASSET_ID,
      suggestedParams,
    });
    const optInTxn = [{txn : txn, signers: [address]}]
    const signedTxn = await deflywallet.signTransaction([optInTxn])
    const success = await algodClient.sendRawTransaction(signedTxn).do();
    return success
  }
  catch(err){
    console.log(err)
    return false
  }
  }


/////////////
const vote_transaction17 = async () => {
  try{
    const suggestedParams = await algodClient.getTransactionParams().do();
    const txn = algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
      from: address,
      to: voteAddress17,
      amount: 1000000,
      assetIndex: ASSET_ID,
      suggestedParams,
    });
    const optInTxn = [{txn : txn, signers: [address]}]
    const signedTxn = await deflywallet.signTransaction([optInTxn])
    const success = await algodClient.sendRawTransaction(signedTxn).do();
    return success
  }
  catch(err){
    console.log(err)
    return false
  }
  }

/////////////
const vote_transaction18 = async () => {
  try{
    const suggestedParams = await algodClient.getTransactionParams().do();
    const txn = algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
      from: address,
      to: voteAddress18,
      amount: 1000000,
      assetIndex: ASSET_ID,
      suggestedParams,
    });
    const optInTxn = [{txn : txn, signers: [address]}]
    const signedTxn = await deflywallet.signTransaction([optInTxn])
    const success = await algodClient.sendRawTransaction(signedTxn).do();
    return success
  }
  catch(err){
    console.log(err)
    return false
  }
  }

/////////////
const vote_transaction19 = async () => {
  try{
    const suggestedParams = await algodClient.getTransactionParams().do();
    const txn = algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
      from: address,
      to: voteAddress19,
      amount: 1000000,
      assetIndex: ASSET_ID,
      suggestedParams,
    });
    const optInTxn = [{txn : txn, signers: [address]}]
    const signedTxn = await deflywallet.signTransaction([optInTxn])
    const success = await algodClient.sendRawTransaction(signedTxn).do();
    return success
  }
  catch(err){
    console.log(err)
    return false
  }
  }

/////////////
const vote_transaction20 = async () => {
  try{
    const suggestedParams = await algodClient.getTransactionParams().do();
    const txn = algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
      from: address,
      to: voteAddress20,
      amount: 1000000,
      assetIndex: ASSET_ID,
      suggestedParams,
    });
    const optInTxn = [{txn : txn, signers: [address]}]
    const signedTxn = await deflywallet.signTransaction([optInTxn])
    const success = await algodClient.sendRawTransaction(signedTxn).do();
    return success
  }
  catch(err){
    console.log(err)
    return false
  }
  }


/////////////
const vote_transaction21 = async () => {
  try{
    const suggestedParams = await algodClient.getTransactionParams().do();
    const txn = algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
      from: address,
      to: voteAddress21,
      amount: 1000000,
      assetIndex: ASSET_ID,
      suggestedParams,
    });
    const optInTxn = [{txn : txn, signers: [address]}]
    const signedTxn = await deflywallet.signTransaction([optInTxn])
    const success = await algodClient.sendRawTransaction(signedTxn).do();
    return success
  }
  catch(err){
    console.log(err)
    return false
  }
  }

/////////////
const vote_transaction22 = async () => {
  try{
    const suggestedParams = await algodClient.getTransactionParams().do();
    const txn = algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
      from: address,
      to: voteAddress22,
      amount: 1000000,
      assetIndex: ASSET_ID,
      suggestedParams,
    });
    const optInTxn = [{txn : txn, signers: [address]}]
    const signedTxn = await deflywallet.signTransaction([optInTxn])
    const success = await algodClient.sendRawTransaction(signedTxn).do();
    return success
  }
  catch(err){
    console.log(err)
    return false
  }
  }


/////////////
const vote_transaction23 = async () => {
  try{
    const suggestedParams = await algodClient.getTransactionParams().do();
    const txn = algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
      from: address,
      to: voteAddress23,
      amount: 1000000,
      assetIndex: ASSET_ID,
      suggestedParams,
    });
    const optInTxn = [{txn : txn, signers: [address]}]
    const signedTxn = await deflywallet.signTransaction([optInTxn])
    const success = await algodClient.sendRawTransaction(signedTxn).do();
    return success
  }
  catch(err){
    console.log(err)
    return false
  }
  }

/////////////
const vote_transaction24 = async () => {
  try{
    const suggestedParams = await algodClient.getTransactionParams().do();
    const txn = algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
      from: address,
      to: voteAddress24,
      amount: 1000000,
      assetIndex: ASSET_ID,
      suggestedParams,
    });
    const optInTxn = [{txn : txn, signers: [address]}]
    const signedTxn = await deflywallet.signTransaction([optInTxn])
    const success = await algodClient.sendRawTransaction(signedTxn).do();
    return success
  }
  catch(err){
    console.log(err)
    return false
  }
  }

//////////
const vote_transaction25 = async () => {
  try{
    const suggestedParams = await algodClient.getTransactionParams().do();
    const txn = algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
      from: address,
      to: voteAddress25,
      amount: 1000000,
      assetIndex: ASSET_ID,
      suggestedParams,
    });
    const optInTxn = [{txn : txn, signers: [address]}]
    const signedTxn = await deflywallet.signTransaction([optInTxn])
    const success = await algodClient.sendRawTransaction(signedTxn).do();
    return success
  }
  catch(err){
    console.log(err)
    return false
  }
  }

//////////
const vote_transaction26 = async () => {
  try{
    const suggestedParams = await algodClient.getTransactionParams().do();
    const txn = algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
      from: address,
      to: voteAddress26,
      amount: 1000000,
      assetIndex: ASSET_ID,
      suggestedParams,
    });
    const optInTxn = [{txn : txn, signers: [address]}]
    const signedTxn = await deflywallet.signTransaction([optInTxn])
    const success = await algodClient.sendRawTransaction(signedTxn).do();
    return success
  }
  catch(err){
    console.log(err)
    return false
  }
  }

//////////
const vote_transaction27 = async () => {
  try{
    const suggestedParams = await algodClient.getTransactionParams().do();
    const txn = algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
      from: address,
      to: voteAddress27,
      amount: 1000000,
      assetIndex: ASSET_ID,
      suggestedParams,
    });
    const optInTxn = [{txn : txn, signers: [address]}]
    const signedTxn = await deflywallet.signTransaction([optInTxn])
    const success = await algodClient.sendRawTransaction(signedTxn).do();
    return success
  }
  catch(err){
    console.log(err)
    return false
  }
  }

//////////
const vote_transaction28 = async () => {
  try{
    const suggestedParams = await algodClient.getTransactionParams().do();
    const txn = algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
      from: address,
      to: voteAddress28,
      amount: 1000000,
      assetIndex: ASSET_ID,
      suggestedParams,
    });
    const optInTxn = [{txn : txn, signers: [address]}]
    const signedTxn = await deflywallet.signTransaction([optInTxn])
    const success = await algodClient.sendRawTransaction(signedTxn).do();
    return success
  }
  catch(err){
    console.log(err)
    return false
  }
  }

//////////////////////////
//////////////////////////
//////////////////////////
//////////////////////////
//////////////////////////
//////////////////////////


// React functions must return a React component
function App() {
  useEffect(() => {
    deflywallet.reconnectSession().then((accounts) => {
      if (accounts.length) {
        localStorage.setItem("address", accounts[0]);
      }
      deflywallet.connector?.on("disconnect", () => {
        localStorage.removeItem("address");
      });
    })
    .catch((e) => console.log(e));
  }, [])

  return (
    <div className="App">
      <header className="App-header">
        <p>
        <h1>
          <div id = "displaytext" style={{ color: "blue" }}> App Under Construction. </div>
        </h1>
        </p>

        <break>
        <h3 id = "displaytext" style={{ color: "blue" }}> ______________________ </h3>
        </break>
      </header>
    </div>
  );

}
export default App
